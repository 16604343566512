<template>
  <div>
    <v-row>
          <v-col cols="8" sm="12">
          <breadcrumbs :items="dataBreadcrumbs" />
          </v-col>
            <v-col cols="4" sm="12" align="right">
            </v-col>
        </v-row>
    <v-tabs
          v-model="tab2"
          centered
          class="tab-index"
        >
          <v-tab
            v-for="item in items"
            :key="item.key"
          >
            {{ item.name }}
          </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab2">
          <v-tab-item
            :key="'price'"
            class="text-center mt-2"
          >
          <interval-price />
          </v-tab-item>
          <v-tab-item
            :key="'weight'"
            class="text-center mt-2"
          >
          <interval-weight />
          </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import IntervalWeight from "@/components/IntervalWeight";
import IntervalPrice from "@/components/IntervalPrice";
import breadcrumbs from "../../components/ui/breadcrumbs";

export default {
  name: "Interval",
  components: {IntervalWeight, IntervalPrice, breadcrumbs},
  props: ["user"],
  created(){
    this.dataBreadcrumbs = [
      {
        text: 'Accueil',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Interval Prix & Poids',
        disabled: true,
        href: '/settings/interval',
      }
    ];
  },
  data: () => ({
    dataBreadcrumbs: [],
    tab: null,
      tab2: null,
      items: [
        {
          name:"Interval de Prix",
          key:"price"
        },
        {
          name:"Interval de Poids",
          key:"weight"
        },
      ],
      //text: 'Although cards can support multiple actions, UI controls, and an overflow menu, use restraint and remember that cards…',
  })
}
</script>
<style lang="scss" scoped>
.theme--light.v-tabs-items {
  background-color: transparent !important;
}
.tab-index {
  position: relative;
  z-index: 1;
}
</style>